<template>
    <div class="">


        <div class="card mb-4" data-container="body">


            <img class="rounded img-fluid" :src="imageUrl()" alt="Course Thumb">

            <!-- <div class="card-body" :style="{ background: item.color_background }">


                <h4 :style="{ color: item.color_title }">
                    {{ item . title }}
                </h4>


                <div class="d-flex justify-content-between mt-3">

                    <div>
                        <h6> Starts at: {{ this . formatDate(item . starts_at) }}</h6>
                    </div>

                    <div>


                        {{ this . momentFromNow(item . starts_at) }}



                    </div>

                </div>



                <div class="d-flex justify-content-between mt-3">

                    <div>

                        <div class="d-flex">
                            <h5 :style="{ color: item.color_title }">
                                ৳{{ item . actual_fee }}.0
                            </h5>
                            &nbsp;
                            <del :style="{ color: item.color_subtitle }"> <strong>৳{{ item . list_fee }}</strong> </del>
                        </div>

                    </div>


                    <div>



                        <h6 :style="{ color: item.color_title }"> {{ item . duration }}
                            Hours
                        </h6>

                    </div>

                </div>



            </div> -->


            <!-- <router-link class="stretched-link"
                :to="{

                    name: 'courses.show',
                    params: { slug: item.slug }

                }"></router-link> -->

            <div class="card-body">


                <div class="d-flex justify-content-between">

                    <!-- <router-link class="btn btn-small btn-info"
                    :to="{

                        name: 'courses.show',
                        params: { slug: item.slug }

                    }">Show</router-link> -->

                    <!-- <router-link class="btn btn-small btn-success"
                    v-if="showApplyButton"
                    :to="{

                        name: 'courses.apply',
                        params: { id: item.id }

                    }">Apply
                    now</router-link> -->

                    <a class="btn btn-small btn-danger">
                        Wishlist
                    </a>

                    <button type="button" class="btn btn-success btn-small" @click="addToCart(item)"
                        v-if=" ! isProductAdded "> <i class="fas fa-cart-plus"></i>&nbsp; Add
                        to cart</button>


                </div>


                <div>{{ msg_text }}</div>

            </div>



        </div>
    </div>
</template>

<script>
    export default {
        name: "CourseCardItem",
        props: ["item"],
        components: {



        },
        mounted: function() {
            this.fetchMyOrder();
        },
        computed: {

            isProductAdded() {

                const filterItems = this.$store.getters.myCart.filter(o => o.id == this.item.id);

                if (filterItems.length == 1) {
                    return true;
                } else {
                    return false;
                }


            },

        },

        data: function() {
            return {
                showApplyButton: false,
                msg_text: "",
            }
        },

        methods: {

            imageUrl() {
                return this.getApiUrl(this.item.image_url)
            },

            fetchMyOrder() {

                let self = this;

                self.$store
                    .dispatch("fetchMyCourseOrder", self.item.id)
                    .then((response) => {
                        self.showApplyButton = false;
                        self.msg_text = response.data.message;
                    })
                    .catch(() => {
                        self.showApplyButton = true;
                    });

            },

            addToCart(product) {
                this.$store
                    .dispatch("addProductToCart", product)
            }

        }

    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
