var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"modal",attrs:{"id":"modal-prevent-closing","title":"Send member request","ok-title":"Send","cancel-title":"Cancel","hide-header":_vm.hideHeader,"centered":"","data-keyboard":"false","data-backdrop":"static"},on:{"show":_vm.initModal,"hidden":_vm.hideModal,"ok":_vm.handleOk,"cancel":_vm.handleCancel},model:{value:(_vm.showMemberRequestModalLocal),callback:function ($$v) {_vm.showMemberRequestModalLocal=$$v},expression:"showMemberRequestModalLocal"}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"p-2"},[_c('LibraryLogo',{attrs:{"library":_vm.library,"size":"48"}})],1),_c('div',{staticClass:"flex-grow-1 p-2"},[_c('h4',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.library.name))]),_c('h6',[(
                                    _vm.library.address.house != null &&
                                        _vm.library.address.house.length != 0
                                )?_c('span',[_vm._v(" "+_vm._s(_vm.library.address.house)+", ")]):_vm._e(),(
                                    _vm.library.address.village != null &&
                                        _vm.library.address.village.length != 0
                                )?_c('span',[_vm._v(" "+_vm._s(_vm.library.address.village)+", ")]):_vm._e(),(_vm.library.address.police_station != null)?_c('span',[_vm._v(" "+_vm._s(_vm.library.address.police_station.name)+", ")]):_vm._e(),(_vm.library.address.district != null)?_c('span',[_vm._v(" "+_vm._s(_vm.library.address.district.name)+" ")]):_vm._e(),(_vm.library.address.post_code != null)?_c('span',[_vm._v(" - "+_vm._s(_vm.library.address.post_code)+" ")]):_vm._e()])])])]),_c('div',{staticClass:"modal-body text-center text-success"},[_c('h4',{staticClass:"modal-title text-primary"},[_vm._v("সদস্য অনুরোধ পাঠান")]),_c('br'),_c('input',{attrs:{"type":"checkbox","id":"iAgreeCheckbox","name":"iAgreeCheckbox"},on:{"click":function($event){_vm.iAgree = !_vm.iAgree}}}),_vm._v("     "),_c('label',{attrs:{"for":"iAgreeCheckbox"}},[_vm._v(" আমি এই পাঠাগারের শর্তাবলী মেনে সদস্য হতে রাজি।")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }