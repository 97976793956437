var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"rounded-circle d-flex justify-content-center",style:({
            'background-image': 'url(' + this.photoUrl + ')',
            // 'background-repeat': 'no-repeat',
            'background-position': 'center',
            // 'background-position': '50px 50px 50px 50px',
            width: _vm.width + 'px',
            height: _vm.height + 'px'
        })},[(this.isItMe(this.currentUser))?_c('a',{staticClass:"text-danger bg-white pl-1 pr-1 rounded mt-auto",on:{"click":function($event){_vm.showUploadMyPhotoModal = !_vm.showUploadMyPhotoModal}}},[_c('i',{staticClass:"fa fa-camera"})]):_vm._e(),_c('UploadMyPhotoModal',{attrs:{"show":_vm.showUploadMyPhotoModal},on:{"updateVisibleState":function($event){_vm.showUploadMyPhotoModal = $event}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }