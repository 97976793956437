<template>
    <div class="">


        <h2>Careers (Upcoming)</h2>
        <ul>
            <li>
               Build resume
            </li>
            <li>
                Find suitable job
            </li>

            <li>
                Connect best employer
            </li>

        </ul>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
    </div>
</template>

<script>
export default {
    name: "Careers",
    components: {},
    mounted: function() {},
    computed: {

    },
    props: {},
    data() {
        return {

        };
    },
    methods:{

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
