<template>
    <div class="">
        <h1>Research</h1>
        Alor Feri focuses on developing technology solutions to improve the
        library experience for patrons and institutions. It involves various
        areas of research, including library management systems, user experience
        design, data analysis, and more. we dedicated to our mission of
        empowering libraries and connecting readers with books and knowledge,
        and the app is a valuable tool to achieve this. The app's features, such
        as attendance tracking, book management, reader management, financial
        management, and social networking, provide a comprehensive solution for
        libraries to manage their operations and engage with their patrons.
        Additionally, the app's performance metrics allow libraries to measure
        their success and make data-driven decisions to continually improve
        their performance. The benefits of using Alor Feri for libraries are
        clear, including streamlining tasks, improving connections with readers,
        providing valuable metrics, and enhancing the overall experience for
        patrons.
    </div>
</template>

<script>
export default {
    name: "Research",
    components: {},
    mounted: function() {},
    computed: {},
    props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
