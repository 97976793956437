import { render, staticRenderFns } from "./LoginContainer.vue?vue&type=template&id=bcdc6d24&scoped=true"
import script from "./LoginContainer.vue?vue&type=script&lang=js"
export * from "./LoginContainer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcdc6d24",
  null
  
)

export default component.exports