<template>
    <div class="about">
        <div class="d-flex justify-content-around">
            <div class="text-center p-2">
                <img
                    src="@/assets/images/defaults/logo_large_en.png"
                    height="180"
                    alt="Alor Feri logo"
                />

            </div>
            <div class="text-center p-2">
                <img
                    src="@/assets/images/defaults/af_mobile_app.png"
                    height="200"
                    alt="Alor Feri logo"
                />
            </div>
            <div class="text-center p-2">
                <img
                    src="@/assets/images/defaults/af_model_car.png"
                    height="200"
                    alt="Alor Feri logo"
                />
            </div>
        </div>

        <p style="text-align:justify">
            <span style="font-weight:bold">
                Alor Feri
            </span>

         is a cutting-edge technology solution that revolutionizes the way libraries manage their operations and connect with their readers. With over 160 libraries utilizing the app, it has proven to be an effective tool for streamlining tasks such as attendance tracking, book management, reader management, and financial management. Furthermore, Alor Feri provides a platform for readers to engage with their local libraries and each other, with features such as book requests, social networking, and the ability to express opinions through posts, comments, likes, and reviews. With a user base of over 2,800 readers, Alor Feri is fostering a stronger bond between libraries and their communities, making the library experience more accessible and enjoyable for all.

            <br>
            <br>
With Alor Feri, libraries have access to a wealth of performance metrics, allowing them to measure their success on a daily, monthly, and yearly basis. This includes important statistics such as the number of books issued and collected, the number of new members joining the library, and the number of new books enrolled in the library's collection. These metrics give libraries valuable insight into their operations, enabling them to make data-driven decisions and continually improve their performance. With Alor Feri, libraries can confidently manage their operations, measure their success, and provide the best possible experience for their patrons.

        </p>

        <p>
            <b>
                The advantages of using Alor Feri app in libraries include:
            </b>

            <br />


            <ul>

                <li>
                    Streamlining library tasks and processes
                </li>

                <li>
                    Improving the connection between the library and its readers
                </li>

                <li>
                    Providing valuable performance metrics to measure the success
                </li>
                <li>
                    Improving the overall experience for library patrons
                </li>
                <li>
                    Enhancing the quality of education and promoting societal enlightenment.
                </li>


            </ul>
        </p>

    </div>
</template>



<script>

export default {
    name: "Products",
    components: {
    },
    mounted: function() {},
    computed: {},
    props: {

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>



