<template>
    <div class="card">




        <div class=" card-header bg-white">
            <h2 class="text-center">Our Products</h2>
        </div>


        <div class="card-body">




<div class="row" >
    <div class="col-md-4">
        <div class="card" style="width: 18rem;">
            <img class="card-img-top"
                src="https://play-lh.googleusercontent.com/V0KaoFUiJYg0ndhEzBmE3zE3So-muLMz5NZZbiHgxI4qab0TJHBtpk6o2E8spw3iq6Q=w240-h480-rw"
                alt="Card image cap">

            <a class="stretched-link" target="_blank" href="https://play.google.com/store/apps/details?id=com.provatsoft.alorferi&pcampaignid=web_share">
            </a>

            <div class="card-footer">
                <h5 class="card-title  text-center">Alor Feri</h5>
            </div>

        </div>
    </div>
    <div class="col-md-4">
        <div class="card" style="width: 18rem;">
            <img class="card-img-top"
                src="@/assets/images/our_products/chutigo_logo.png"
                alt="Card image cap">
                <a class="stretched-link" target="_blank" href="https://play.google.com/store/apps/details?id=com.provatsoft.apps.govtholidaysbd&pcampaignid=web_share">
            </a>

            <div class="card-footer">
                <h5 class="card-title  text-center">ChutiGo</h5>
            </div>
        </div>
    </div>

    <div class="col-md-4">
        <div class="card" style="width: 18rem;">
            <img class="card-img-top"
                src="https://play-lh.googleusercontent.com/a_JAC7FtXCyrAAaJg1XFTDQorp83l5JTu0KFtIgDiqBXseBMdpu-zH0o5pdxno1MAWSm=w240-h480-rw"
                alt="Card image cap">

                <a class="stretched-link" target="_blank" href="https://play.google.com/store/apps/details?id=com.provatsoft.apps.flag71&pcampaignid=web_share">
            </a>


            <div class="card-footer">
                <h5 class="card-title text-center ">Flag71</h5>
            </div>
        </div>
    </div>
</div>
</div>

    </div>
  </template>

  <script>

  export default {
    name: "OurProducts",
    components: {

    },
  };
  </script>
