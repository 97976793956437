<template>
    <div class="">

            <!-- What we do -->
            <div class="card">
            <div class="card-body">


<h1 class="display-4">Service We offered</h1>


<div class="row align-items-center m-2">
    <div class="col-lg-7">
        <h3>Mobile App Development</h3>
        <p class="text-justify"> Proficient in Flutter,
            Android, and iOS technologies,
            Alor Feri crafts engaging mobile applications that captivate users across various
            devices.</p>
    </div>
    <div class="col-lg-5">
        <img  class="rounded img-fluid"
            src="https://www.skylark.com.sg/blog/wp-content/uploads/2021/07/Cost-of-Mobile-App-Development-in-Singapore.jpg"
            alt="Image" >
    </div>
</div>

<div class="row align-items-center m-2">
    <div class="col-lg-7">
        <h3>Software Development</h3>
        <p class="text-justify"> Leveraging technologies like
            Laravel and Vue.Js, Alor Feri
            designs and develops robust web-based applications tailored to different
            platforms, ensuring seamless user experiences.</p>
    </div>
    <div class="col-lg-5">
        <img  class="rounded img-fluid"
            src="https://www.appstudio.ca/blog/wp-content/uploads/2020/09/Modern-Software-Development.jpg"
            alt="Image" >
    </div>
</div>

<div class="row align-items-center m-2">
    <div class="col-lg-7">
        <h3>Software as a Service (SaaS)</h3>
        <p class="text-justify"> Alor Feri has pioneered in SaaS offerings, exemplified
            by its Alor Feri Mobile App. This platform provides SaaS-based library management
            system that connects over 200 libraries and serves more than 2000 readers,
            offering a seamless book reading experience across Bangladesh.</p>
    </div>
    <div class="col-lg-5">
        <img  class="rounded img-fluid" src="https://www.milesweb.in/images/saas/saas-intro-1.png"
            alt="Image" >
    </div>
</div>

<div class="row align-items-center m-2">
    <div class="col-lg-7">
        <h3>Training Programs</h3>
        <p class="text-justify"> Beyond developing software, Alor Feri extends its expertise to
            provide comprehensive training programs, empowering professionals and
            organizations to navigate the ever-evolving tech landscape. Our training programs
            are like tech survival guides, helping professionals and organizations stay on top of
            the latest in mobile app, software, web, and backend services development.
            Because in a world that's always changing, we believe knowledge is the best tool
            you can have.
        </p>
    </div>
    <div class="col-lg-5">
        <img  class="rounded img-fluid"
            src="https://blog.vantagecircle.com/content/images/2021/07/diversity-training-3.png" alt="Image"
            >
    </div>
</div>






            </div>

        </div>



    </div>
</template>

<script>
export default {

    components: {},
    mounted: function() {},
    computed: {

    },
    props: {},
    data() {
        return {

        };
    },
    methods:{

    }
};
</script>


<style scoped></style>
