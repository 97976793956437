var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('LibraryCoverPhotoWithUpload',{attrs:{"user":this.$store.getters.activeUser,"library":_vm.library}}),_c('div',{staticClass:"d-flex flex-column pt-2 pl-2 pr-2 bg-light card"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"p-2"},[_c('LibraryLogoWithUpload',{attrs:{"library":_vm.library,"size":"96"}})],1),_c('div',{staticClass:"flex-grow-1 p-2"},[_c('h3',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.library.name))]),_c('h5',[(
                            _vm.library.address.house != null &&
                                _vm.library.address.house.length != 0
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.library.address.house)+", ")]):_vm._e(),(
                            _vm.library.address.village != null &&
                                _vm.library.address.village.length != 0
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.library.address.village)+", ")]):_vm._e(),(_vm.library.address.police_station != null)?_c('span',[_vm._v(" "+_vm._s(_vm.library.address.police_station.name)+", ")]):_vm._e(),(_vm.library.address.district != null)?_c('span',[_vm._v(" "+_vm._s(_vm.library.address.district.name)+" ")]):_vm._e(),(_vm.library.address.post_code != null)?_c('span',[_vm._v(" - "+_vm._s(_vm.library.address.post_code)+" ")]):_vm._e()]),_c('LibraryFollowersModal',{attrs:{"show":_vm.showLibraryFollowers,"library":_vm.library},on:{"updateVisibleState":function($event){_vm.showLibraryFollowers = $event}}}),_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-sm btn-link mt-1 mb-1 mr-1",on:{"click":function($event){_vm.showLibraryFollowers = !_vm.showLibraryFollowers}}},[_vm._v(" "+_vm._s(_vm.totalFollowers)+" ")]),_vm._m(0),_c('div',{staticClass:"dropdown-menu"},[(!_vm.isFollowingByMe)?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){return _vm.followLibraryByMeAction()}}},[_vm._v("Follow")]):_vm._e(),(_vm.isFollowingByMe)?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){return _vm.unFollowLibraryByMeAction()}}},[_vm._v("Unfollow")]):_vm._e(),(
                                _vm.myLibraryMembership == null &&
                                    _vm.computeMyLibraryMemberRequest == null
                            )?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){_vm.showMemberRequestModal = true}}},[_vm._v(" Request to be member ")]):_vm._e(),(_vm.computeMyLibraryMemberRequest != null)?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v(" Member request sent ")]):_vm._e()])])],1),_c('div',{staticClass:"p-2"},[_c('img',{attrs:{"src":_vm.getQRCodeUrl(_vm.library),"alt":"QR Code","width":"64","height":"64"}})])]),_c('div',{staticClass:"pt-1"},[_c('ul',{staticClass:"nav nav-tabs nav-justified"},_vm._l((_vm.getTabs),function(tab,index){return _c('li',{key:index,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{ active: tab.active },attrs:{"href":"#"},on:{"click":function($event){return _vm.clickTabItem(tab)}}},[_vm._v(_vm._s(tab.title))])])}),0)])]),_c('CreateLibraryMemberRequestModal',{attrs:{"showMemberRequestModal":_vm.showMemberRequestModal,"library":_vm.library},on:{"onUpdateVisibleState":function($event){_vm.showMemberRequestModal = $event},"onClickOk":function($event){_vm.myLibraryMemberRequest = $event}}}),_c(_vm.tabBody,{tag:"component",attrs:{"library":_vm.library}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"dropdown-toggle dropdown-toggle-split btn btn-sm btn-link mt-1 mb-1 border-top border-left border-right border-bottom",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Toggle Dropdown")])])
}]

export { render, staticRenderFns }